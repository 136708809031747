#analytics-root {
  text-align: center;
}
#analytics-root .hero {
  padding: 60px 0;
  width: 100%;
  background: #e13c34;
}
#analytics-root .hero h1 {
  color: #fff;
  margin-bottom: 60px;
  font-size: 1.4em;
}
#analytics-root .hero h2 {
  color: #fff;
  font-size: 2em;
  display: inline-block;
  margin: 0 60px;
}
#analytics-root .hero h2 span {
  display: block;
  margin-bottom: 6px;
  font-size: 1.5em;
}
#analytics-root .hero .dates {
  margin-bottom: 30px;
}
#analytics-root .hero .dates span {
  color: #fff;
}
#analytics-root .hero .react-datepicker-wrapper {
  width: 100px;
  display: inline-block !important;
}
#analytics-root .hero .react-datepicker-wrapper input {
  background: none;
  color: #fff;
  text-align: left;
}
#analytics-root .hero .react-datepicker-wrapper input:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
#analytics-root .buttons {
  margin-bottom: 40px;
}
#analytics-root .buttons button {
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
}
#analytics-root .buttons button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
#analytics-root .buttons button:nth-child(2) {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.charts {
  width: 100%;
  clear: both;
  display: block;
  position: relative;
}

.chart {
  width: 400px !important;
  margin: 10px 1%;
  display: inline-block !important;
}
