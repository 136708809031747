#provider-root {
  background: #242424;
  min-height: 100vh;
  width: 100%;
  text-align: center;
  padding-bottom: 30px;
}
#provider-root .description a {
  color: #e13c34 !important;
}
#provider-root .chat, #provider-root .newMsgContainer {
  width: 100%;
}
#provider-root .chat span, #provider-root .newMsgContainer span {
  font-size: 0.8em;
}
#provider-root .chat input, #provider-root .chat textarea, #provider-root .newMsgContainer input, #provider-root .newMsgContainer textarea {
  width: 100%;
  background: none;
  color: #f7f7f7;
  height: 50px;
  display: inline-block;
  text-align: left;
  margin: 20px 0;
  font-size: 0.8em;
  border: 1px solid #f7f7f7;
}
#provider-root .chat input::-webkit-input-placeholder, #provider-root .chat textarea::-webkit-input-placeholder, #provider-root .newMsgContainer input::-webkit-input-placeholder, #provider-root .newMsgContainer textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #f7f7f7;
}
#provider-root .chat input::-moz-placeholder, #provider-root .chat textarea::-moz-placeholder, #provider-root .newMsgContainer input::-moz-placeholder, #provider-root .newMsgContainer textarea::-moz-placeholder { /* Firefox 19+ */
  color: #f7f7f7;
}
#provider-root .chat input:-ms-input-placeholder, #provider-root .chat textarea:-ms-input-placeholder, #provider-root .newMsgContainer input:-ms-input-placeholder, #provider-root .newMsgContainer textarea:-ms-input-placeholder { /* IE 10+ */
  color: #f7f7f7;
}
#provider-root .chat input:-moz-placeholder, #provider-root .chat textarea:-moz-placeholder, #provider-root .newMsgContainer input:-moz-placeholder, #provider-root .newMsgContainer textarea:-moz-placeholder { /* Firefox 18- */
  color: #f7f7f7;
}
#provider-root h1 {
  color: #fff;
  font-size: 1.3em;
  padding: 30px 0;
}
#provider-root .taskStatus {
  font-size: 0.9em;
}
#provider-root h2 {
  color: #fff;
  clear: both;
  font-size: 1.1em;
  margin: 10px 0;
  padding-top: 30px;
  font-weight: bold;
  text-align: left;
}
#provider-root h5 {
  display: block;
  text-align: left;
  color: #e13c34;
  font-size: 0.8em;
  margin-bottom: 30px;
}
#provider-root h5 strong {
  color: #fff;
}
#provider-root p {
  color: #fff;
  font-size: 1em;
  text-align: left;
  padding-bottom: 10px;
  line-height: 1.3;
}
#provider-root p strong {
  font-weight: bold;
}
#provider-root .logo {
  width: 120px;
  margin-top: 60px;
}
#provider-root .addressContainer {
  margin: 20px 0;
  position: relative;
}
#provider-root .addressBlock {
  margin: 20px 0;
}
#provider-root .addresses {
  margin-top: 20px;
}
#provider-root label {
  font-size: 0.85em;
  display: block;
  text-align: left;
  color: #939393;
}
#provider-root label i {
  color: #e13c34;
  float: right;
}
#provider-root .fa-check, #provider-root .fa-trash {
  color: #e13c34;
  position: absolute;
  top: 0;
  right: 0;
}
#provider-root .addressName, #provider-root .address {
  background: none;
  color: #fff;
  font-weight: bold;
  font-size: 0.9em;
  text-align: left;
  padding: 0;
  height: auto;
  margin: 0;
  text-indent: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}
#provider-root .addressName::-webkit-input-placeholder, #provider-root .address::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #f7f7f7;
}
#provider-root .addressName::-moz-placeholder, #provider-root .address::-moz-placeholder { /* Firefox 19+ */
  color: #f7f7f7;
}
#provider-root .addressName:-ms-input-placeholder, #provider-root .address:-ms-input-placeholder { /* IE 10+ */
  color: #f7f7f7;
}
#provider-root .addressName:-moz-placeholder, #provider-root .address:-moz-placeholder { /* Firefox 18- */
  color: #f7f7f7;
}
#provider-root .address {
  text-align: left;
  display: block;
  font-size: 0.95em;
  text-decoration: underline;
  line-height: 1.2;
  margin-top: 3px;
}
#provider-root .buttonContainer {
  height: 60px;
  position: relative;
  margin: 50px 0 15px 0;
}
#provider-root .primary-btn {
  height: 100%;
  width: 100%;
  height: 60px;
  background: #00afb1;
}
#provider-root .primary-btn:hover {
  color: #fff !important;
}
#provider-root .primary-btn.white {
  background: #fff;
  color: #e13c34;
  width: 49%;
  font-size: 0.9em;
}
#provider-root .primary-btn.white:hover {
  color: #e13c34 !important;
}
#provider-root .half-btn {
  height: 0;
  width: 56%;
  background: none;
  position: absolute;
  line-height: 60px;
}
#provider-root .half-btn.left {
  border-right: 50px solid transparent;
  border-bottom: 60px solid;
  left: 0;
}
#provider-root .half-btn.left button {
  top: 0;
}
#provider-root .half-btn.left.red {
  border-bottom-color: #e13c34;
}
#provider-root .half-btn.left.yellow {
  border-bottom-color: #ff9000;
}
#provider-root .half-btn.right {
  border-left: 50px solid transparent;
  border-top: 60px solid;
  right: 0;
}
#provider-root .half-btn.right button {
  bottom: 0;
}
#provider-root .half-btn.right.green {
  border-top-color: #00cf00;
}
#provider-root .half-btn button {
  position: absolute;
  background: transparent;
  height: 60px;
  width: 100%;
  font-weight: bold;
  font-size: 0.9em;
  left: 0;
  color: #fff;
  text-align: center;
}
#provider-root .signatureOpen {
  background: #fff;
  color: #242424;
  height: 50px;
  line-height: 50px;
  font-size: 0.9em;
  text-align: left;
  padding: 0 20px;
  position: relative;
  margin-top: 10px;
}
#provider-root .signatureOpen .fa-pen {
  float: right;
  margin-top: 15px;
  color: #242424;
}
#provider-root .signature, #provider-root .expense {
  position: absolute;
  background: #fff;
  width: 100%;
  z-index: 2;
  padding: 20px 0;
  margin-top: -50px;
}
#provider-root .signature a, #provider-root .expense a {
  color: #e13c34;
  float: right;
  margin-bottom: 15px;
  display: block;
}
#provider-root .signature a i, #provider-root .expense a i {
  color: #e13c34;
}
#provider-root .signature input, #provider-root .signature select, #provider-root .expense input, #provider-root .expense select {
  background: #eee;
  color: #e13c34;
  font-size: 0.9em;
  clear: both;
  text-align: left;
}
#provider-root .signature input::-webkit-input-placeholder, #provider-root .signature select::-webkit-input-placeholder, #provider-root .expense input::-webkit-input-placeholder, #provider-root .expense select::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #e13c34;
}
#provider-root .signature input::-moz-placeholder, #provider-root .signature select::-moz-placeholder, #provider-root .expense input::-moz-placeholder, #provider-root .expense select::-moz-placeholder { /* Firefox 19+ */
  color: #e13c34;
}
#provider-root .signature input:-ms-input-placeholder, #provider-root .signature select:-ms-input-placeholder, #provider-root .expense input:-ms-input-placeholder, #provider-root .expense select:-ms-input-placeholder { /* IE 10+ */
  color: #e13c34;
}
#provider-root .signature input:-moz-placeholder, #provider-root .signature select:-moz-placeholder, #provider-root .expense input:-moz-placeholder, #provider-root .expense select:-moz-placeholder { /* Firefox 18- */
  color: #e13c34;
}
#provider-root .signature .primary-btn, #provider-root .expense .primary-btn {
  background: #e13c34;
  height: 50px;
  line-height: 50px;
  margin-top: 20px;
}
#provider-root .signature .primary-btn:hover, #provider-root .expense .primary-btn:hover {
  background: #e13c34;
}
#provider-root .signature .sigCanvas, #provider-root .expense .sigCanvas {
  background: #fff;
  position: relative;
  border: 2px solid #eeeeee;
  margin-top: 5px;
  clear: both;
}
#provider-root .expense {
  margin-top: 0;
}
