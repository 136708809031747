#tasks-root .fa-table, #tasks-root .fa-trello {
  position: absolute;
  top: 5px;
  right: -10px;
  color: #ccc;
  font-size: 1.5em;
  cursor: pointer;
  transition: color 0.3s ease;
}
@media screen and (max-width: 768px) {
  #tasks-root .fa-table, #tasks-root .fa-trello {
    display: none;
  }
}
#tasks-root .fa-table:hover, #tasks-root .fa-trello:hover {
  color: #e13c34;
}
#tasks-root .console {
  clear: both;
  display: block;
  width: 100%;
}
#tasks-root .console .third {
  text-align: left;
}
#tasks-root .console label {
  color: #e13c34;
  font-size: 1em;
  padding-bottom: 10px;
  display: inline-block;
  font-weight: bold;
}
#tasks-root .console input {
  height: 40px !important;
}
#tasks-root .console .searchSelect {
  height: 40px;
  border-color: #333;
  display: inline-block;
  margin-left: 10px;
  top: 5px;
  width: 200px;
  position: relative;
}
#tasks-root .console .searchSelect .react-select__control, #tasks-root .console .searchSelect .react-select__value-container, #tasks-root .console .searchSelect .react-select__input, #tasks-root .console .searchSelect .react-select__input input {
  height: 100%;
  color: #333;
  border: none !important;
  box-shadow: none !important;
}
#tasks-root .console .searchSelect .react-select__placeholder, #tasks-root .console .searchSelect .react-select__single-value {
  color: #333;
}
#tasks-root .console .searchSelect .react-select__value-container--has-value .react-select__single-value {
  top: 55%;
}
#tasks-root .console .searchSelect .react-select__input, #tasks-root .console .searchSelect .react-select__input input {
  height: 38px !important;
  border: none !important;
}
#tasks-root .console .searchSelect .react-select__input:focus, #tasks-root .console .searchSelect .react-select__input input:focus {
  border: none;
  box-shadow: none !important;
}
#tasks-root .board {
  width: 95%;
  max-width: 1100px;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: auto;
  text-align: center;
}
#tasks-root .board .column {
  width: 20%;
  display: inline-block;
  margin: 0 20px;
  vertical-align: top;
}
@media screen and (max-width: 768px) {
  #tasks-root .board .column {
    width: 100%;
    margin: 0;
    display: block;
    min-height: calc(100vh - 170px);
  }
}
#tasks-root .board .column .task {
  background: #fff;
  width: 100%;
  height: 90px;
  margin: 5px 0;
  position: relative;
  transition: all 0.3s ease;
  text-align: center;
}
#tasks-root .board .column .task.stillActive {
  border: 2px solid #ff9000;
}
#tasks-root .board .column .task.draft {
  opacity: 0.7;
}
#tasks-root .board .column .task.draft h5 {
  color: #e13c34;
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 0.7em;
}
#tasks-root .board .column .task:hover {
  transform: scale(1.05);
}
#tasks-root .board .column .task .fa-trash {
  color: #e13c34;
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 0.7em;
  cursor: pointer;
}
#tasks-root .board .column .task h4 {
  font-size: 0.9em;
  padding: 8px 0 5px;
  cursor: pointer;
  color: #000;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 60%;
  display: inline-block;
  text-align: center;
}
#tasks-root .board .column .task > span {
  font-size: 0.8em;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;
  text-align: center;
  margin: 3px 0;
}
#tasks-root .board .column .task > span span {
  color: #a2a2a2;
  font-size: 0.9em;
  margin-right: 2px;
}
#tasks-root .board .column .columnHeader {
  height: 30px;
  background: #fff;
  text-align: center;
  width: 100%;
  line-height: 33px;
  font-size: 1em;
  position: relative;
  margin-bottom: 5px;
  vertical-align: top;
}
#tasks-root .board .column .columnHeader:after {
  content: "";
  height: 4px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}
