#login-root {
  height: 100%;
  width: 100%;
}
#login-root .left {
  width: 40%;
  background: #252c3a;
  height: 100%;
  position: relative;
}
@media screen and (max-width: 768px) {
  #login-root .left {
    width: 100%;
    float: none;
    position: absolute;
    left: 0;
    top: 0;
  }
}
#login-root .right {
  width: 60%;
  height: 100%;
  background: url("../../assets/login_bg.jpg") no-repeat center;
  background-size: cover;
  position: relative;
}
@media screen and (max-width: 768px) {
  #login-root .right {
    display: none;
  }
}
#login-root a {
  color: #f7f7f7;
  display: block;
  cursor: pointer;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  margin: 30px auto;
  width: 150px;
}
#login-root a:hover {
  color: #e13c34;
}
#login-root form {
  width: 400px;
  height: 220px;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
}
@media screen and (max-width: 768px) {
  #login-root form {
    width: 90%;
  }
}
#login-root form img {
  width: 150px;
  margin-bottom: 20px;
}
#login-root form input {
  text-align: left;
}
#login-root form button {
  width: 100%;
  margin-top: 10px;
}
#login-root form button.off {
  background: #2d3444;
  cursor: pointer;
}
#login-root form button.off:hover {
  background: #2d3444;
  color: #f7f7f7;
  cursor: pointer;
}
#login-root form h5 {
  color: #e13c34;
  margin-top: 10px;
}
