/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  text-decoration: none;
  box-sizing: border-box;
}

select, input, textarea, select, button {
  outline: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  resize: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  font-family: "Hind", sans-serif;
  font-weight: 300;
}

body,
html {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background: #242424;
}
@media screen and (max-width: 768px) {
  body,
  html {
    font-size: 16px;
  }
}

#root,
#app-root {
  height: 100%;
  width: 100%;
}

input,
select,
textarea {
  border-radius: 2px;
  font-size: 1em;
  padding: 8px;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  text-indent: 8px;
  display: block;
}

input,
select,
textarea,
button {
  transition: all 0.3s ease;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  box-shadow: 0 0 20px rgb(81, 203, 238) !important;
}

.focused {
  border: 1px solid #00ff84 !important;
}

input[type=checkbox],
input[type=radio],
.selectContainer input,
.-pageJump input,
input.search {
  border: none !important;
}

.clearfix {
  display: block;
  width: 100%;
  clear: both;
}

.container {
  width: 90%;
  height: 100%;
  max-width: 1150px;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.container:after {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.right {
  float: right;
}

.left {
  float: left;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  height: 100%;
  min-height: 1px;
}

.slick-slider .slick-track {
  position: absolute;
}

.primary-btn {
  color: #f7f7f7;
  background: #e13c34;
  font-size: 1em;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}
.primary-btn:hover {
  background: #f7f7f7;
  color: #e13c34;
}
.primary-btn.green {
  background: #00e390;
}
.primary-btn.green:hover {
  background: #f7f7f7;
  color: #00e390;
}

.second-btn {
  color: #242424;
  background: #e8e8e8;
  font-size: 1em;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}
.second-btn:hover {
  background: #e13c34;
  color: #e8e8e8;
}

.innerWindow {
  position: relative;
  width: 100%;
  float: right;
  padding-bottom: 10px;
  background: #f6f6f6;
  min-height: 100vh;
  z-index: 99;
}
.innerWindow.open {
  width: calc(100% - 250px);
}
@media screen and (max-width: 768px) {
  .innerWindow {
    width: 100% !important;
    float: none;
    top: 50px;
    min-height: calc(100vh - 130px);
  }
}

.buttonsHeader {
  height: 50px;
  background: #fff;
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 8px;
}
.buttonsHeader button {
  display: inline-block;
  background: #f1f1f1;
  color: #e13c34;
  font-size: 1em;
  font-weight: bold;
  padding: 10px 0;
  width: 200px;
  margin: 0 20px;
  margin-top: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .buttonsHeader button {
    width: 140px;
    font-size: 0.9em;
    margin: 0 10px;
    margin-top: 15px;
  }
}
.buttonsHeader button:hover {
  background: #e13c34;
  color: #fff;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #252c3a;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #252c3a;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #252c3a;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #252c3a;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e13c34;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e13c34;
}

.ReactTable .fa-edit {
  color: #000;
  margin-right: 10px;
}

.ReactTable .fa-trash {
  color: #e13c34;
}

h4.taskStatus {
  float: right;
  font-size: 1.2em;
  color: #000;
  margin-top: 25px;
  text-transform: capitalize;
  margin-left: 30px;
}
@media screen and (max-width: 768px) {
  h4.taskStatus {
    width: 100%;
    text-align: center;
    float: none;
    margin: 40px 0;
    display: block;
  }
}
h4.taskStatus .status {
  user-select: none;
}
h4.taskStatus .status:after {
  content: "";
  display: inline-block;
  margin-left: 8px;
  height: 13px;
  width: 13px;
  position: relative;
  top: 1px;
  border-radius: 50%;
}
h4.taskStatus .status.active, h4.taskStatus .status.assigned {
  cursor: pointer;
}

.unassigned {
  color: #e13c34;
}
.unassigned:after {
  background: #e13c34;
}

.assigned {
  color: #00afb1;
}
.assigned:after {
  background: #00afb1;
}

.active {
  color: #ff9000;
}
.active:after {
  background: #ff9000;
}

.completed {
  color: #00cf00;
}
.completed:after {
  background: #00cf00;
}

.chat {
  margin-top: 30px !important;
  border: 1px solid #f7f7f7;
  width: 100%;
  height: 150px !important;
  padding: 20px;
  margin-bottom: 0 !important;
  width: calc(100% - 20px);
  overflow-wrap: break-word;
  overflow-y: scroll;
}
.chat span {
  display: block;
  margin-bottom: 10px;
  line-height: 1.4;
  color: #000;
  text-align: left;
  font-size: 1em;
}
.chat span a {
  color: #000;
}
.chat span.red {
  color: #e13c34;
}
.chat span.red a {
  color: #e13c34;
}
.chat span.grey {
  color: #000;
}
.chat span.grey a {
  color: #000;
}
.chat span a {
  text-decoration: underline;
}

.newMsgContainer {
  position: relative;
  width: 100%;
}
.newMsgContainer textarea {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 70px !important;
  line-height: 30px;
  max-height: 150px;
}

.sendMsg {
  color: #e13c34;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  padding: 18px 20px 18px 0;
  right: 10px;
  cursor: pointer;
}

.pac-container,
.suggestions {
  background: #e8e8e8;
  width: 100%;
}
.pac-container:after,
.suggestions:after {
  display: none !important;
}
.pac-container .pac-icon,
.suggestions .pac-icon {
  display: none !important;
}
.pac-container .pac-item,
.suggestions .pac-item {
  color: #e13c34;
  font-size: 0.8em;
  padding: 12px 10px;
  line-height: 1.3;
  cursor: pointer;
}
.pac-container .pac-item .pac-item-query,
.suggestions .pac-item .pac-item-query {
  color: #e13c34;
}
.pac-container .pac-item:hover,
.suggestions .pac-item:hover {
  background: #f7f7f7;
}

#edit-account-root .QBSub label {
  color: #fff;
  float: left;
  margin-left: 12px;
  margin-right: 20px;
}
#edit-account-root .QBSub input[type=checkbox] {
  border: 1px solid #fff !important;
  margin: 0 !important;
  top: -2px;
  position: relative;
}
#edit-account-root input[type=checkbox],
#edit-account-root input[type=radio] {
  height: 10px;
  width: 10px;
  background: #242424;
  float: left;
  margin-left: 22px;
  cursor: pointer;
  border: none !important;
  box-shadow: none !important;
}
@media screen and (max-width: 768px) {
  #edit-account-root input[type=checkbox],
  #edit-account-root input[type=radio] {
    margin-left: 0;
  }
}
#edit-account-root input[type=checkbox]:checked,
#edit-account-root input[type=radio]:checked {
  background: #e13c34;
}

.loadingDiv {
  height: 100%;
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
}
.loadingDiv svg {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
