nav {
  background: #fff;
  height: 100vh;
  width: 250px;
  margin-left: 0;
  margin-right: 30px;
  position: fixed;
  left: -250px;
  top: 0;
  z-index: 100;
}
nav .toggleDesktopMenu {
  background: #fff;
  height: 30px;
  width: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  right: -15px;
}
nav .toggleDesktopMenu i {
  color: #252c3a;
  transform: rotate(-90deg);
  line-height: 30px;
  font-size: 16px;
  margin-left: 3px;
}
nav.open {
  left: 0;
}
nav.open .toggleDesktopMenu i {
  transform: rotate(90deg);
  margin-left: 1px;
}
@media screen and (max-width: 768px) {
  nav {
    width: 100%;
    z-index: 1000;
    margin-right: 0;
    float: none;
    left: 0;
    top: calc(-100vh + 50px);
    transition: all 0.3s ease;
  }
  nav .toggleDesktopMenu {
    display: none;
  }
  nav.open {
    top: 0;
  }
}
nav .mobileIcon {
  display: none;
}
@media screen and (max-width: 768px) {
  nav .mobileIcon {
    color: #000;
    position: absolute;
    bottom: 20px;
    font-size: 1.1em;
    left: 20px;
    display: block;
  }
}
nav img {
  width: 120px;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  bottom: 30px;
}
@media screen and (max-width: 768px) {
  nav img {
    width: 90px;
    bottom: 20px;
  }
}
nav .profile {
  width: 100%;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  display: block;
}
nav .profile .bell {
  color: #fff;
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  background: #e13c34;
  border-radius: 50%;
  line-height: 20px;
  font-size: 0.8em;
  user-select: none;
}
nav .profile .bell:hover > span {
  display: block;
}
nav .profile .bell span {
  display: none;
  position: absolute;
  left: 30px;
  top: 0;
  background: #e13c34;
  color: #fff;
  width: 150px;
  padding: 10px;
  line-height: 1.2;
  text-align: left;
  border-radius: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
@media screen and (max-width: 768px) {
  nav .profile .bell {
    display: none;
  }
}
nav .profile .image {
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 20px;
}
@media screen and (max-height: 580px) and (max-width: 768px) {
  nav .profile .image {
    display: none;
  }
}
nav .profile .name {
  color: #000;
  cursor: pointer;
  user-select: none;
}
@media screen and (max-height: 580px) and (max-width: 768px) {
  nav .profile .name {
    margin-top: 30px;
    display: block;
  }
}
nav .profile .name i {
  margin-left: 5px;
}
nav .profile .userMenu {
  display: none;
  width: 150px;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 0.9em;
}
nav .profile .userMenu.open {
  display: block;
}
nav .profile .userMenu li {
  color: #000;
  cursor: pointer;
  user-select: none;
  margin: 15px 0;
}
nav .profile .userMenu li a {
  color: #000;
}
nav .mainMenu {
  transition: all 0.3s ease;
}
nav .mainMenu a {
  display: block;
  padding: 0 50px;
  height: 45px;
  line-height: 45px;
  font-size: 1em;
  user-select: none;
  color: #000;
  position: relative;
  transition: all 0.3s ease;
}
nav .mainMenu a:hover {
  background: #2d3444;
  color: #fff;
}
nav .mainMenu a.active {
  background: #2d3444;
  color: #fff;
}
nav .mainMenu a.active:before {
  content: "";
  height: 100%;
  width: 4px;
  background: #e13c34;
  left: 0;
  top: 0;
  display: block;
  position: absolute;
}
