#chat-root .users {
  background: #8a92a7;
  height: 100vh;
  left: 0;
  position: absolute;
  width: 120px;
  top: 0;
  text-align: center;
}
#chat-root .users .box {
  border-bottom: 1px solid #adb1bd;
}
#chat-root .users .user {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60px;
  width: 60px;
  display: block;
  border-radius: 50%;
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: #8a92a7;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  margin: 15px auto;
}
#chat-root .users .user.selected {
  border-color: #00ff84;
}
#chat-root .chats {
  background: #f1efef;
  height: 100vh;
  left: 120px;
  position: absolute;
  width: 280px;
  top: 0;
  border-right: 1px solid #252c3a;
  overflow-y: scroll;
}
#chat-root .chats li {
  height: 50px;
  line-height: 30px;
  margin: 10px 0;
  background: #fff;
  padding: 10px;
  position: relative;
  cursor: pointer;
  color: #000 !important;
}
#chat-root .chats li.selected {
  background: #e13c34;
  color: #fff !important;
}
#chat-root .chats li:after {
  position: absolute;
  right: 5px;
  height: 10px;
  width: 10px;
  background: transparent;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto 0;
  display: block;
  content: "";
}
#chat-root .chats li.unassigned:after {
  background: red;
  border: 1px solid #252c3a;
}
#chat-root .chats li.assigned:after {
  background: yellow;
  border: 1px solid #252c3a;
}
#chat-root .chats li.done:after {
  background: #00ff84;
  border: 1px solid #252c3a;
}
#chat-root #messages-root {
  height: 100vh;
  right: 0;
  position: absolute;
  width: calc(100% - 400px);
  overflow: hidden;
  top: 0;
}
#chat-root #messages-root .header {
  background: #252c3a;
  width: 100%;
  height: 50px;
  position: absolute;
  top: 0;
  z-index: 3;
  left: 0;
  color: #fff;
  line-height: 50px;
  padding: 0 10px;
  overflow: hidden;
}
#chat-root #messages-root .header a {
  color: #fff;
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
  float: right;
  font-size: 0.8em;
}
#chat-root #messages-root .chatBox {
  height: calc(100% - 50px);
  min-height: calc(100% - 150px);
  overflow-y: scroll;
  padding: 20px 0;
  background: #fff;
  padding-top: 70px;
}
#chat-root #messages-root .message {
  width: 300px;
  height: auto;
  padding: 10px;
  border-radius: 4px;
  margin: 5px 10px;
  clear: both;
  line-height: 1.3;
  overflow-wrap: break-word;
}
#chat-root #messages-root .message.left {
  background: #e13c34;
  color: #fff;
}
#chat-root #messages-root .message.left a {
  color: #fff;
}
#chat-root #messages-root .message.right {
  background: #f1efef;
}
#chat-root #messages-root .message.notification {
  background: #252c3a;
  color: #fff;
}
#chat-root #messages-root .message.notification a {
  color: #fff;
}
#chat-root #messages-root .message span {
  font-size: 0.7em;
  text-align: right;
  width: 100%;
  display: block;
  padding-bottom: 10px;
}
#chat-root #messages-root .message a {
  color: #000;
  text-decoration: underline;
}

.sendBox {
  position: relative;
  width: 100%;
  border-top: 1px solid #252c3a;
}
.sendBox textarea {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 70px !important;
  line-height: 30px;
  max-height: 150px;
}
.sendBox .sendMsg {
  color: #e13c34;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  padding: 18px 20px 18px 0;
  right: 10px;
  cursor: pointer;
}
