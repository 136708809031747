#tasks-archive-root .searchConsole {
  clear: both;
  display: block;
}
#tasks-archive-root .searchConsole label {
  color: #e13c34;
  font-size: 1em;
  padding-bottom: 10px;
  display: block;
  font-weight: bold;
}
#tasks-archive-root .searchConsole input {
  height: 40px !important;
}
#tasks-archive-root .searchConsole .searchSelect {
  height: 40px;
  border-color: #333;
}
#tasks-archive-root .searchConsole .searchSelect .react-select__control, #tasks-archive-root .searchConsole .searchSelect .react-select__value-container, #tasks-archive-root .searchConsole .searchSelect .react-select__input, #tasks-archive-root .searchConsole .searchSelect .react-select__input input {
  height: 100%;
  color: #333;
  border: none !important;
  box-shadow: none !important;
}
#tasks-archive-root .searchConsole .searchSelect .react-select__placeholder, #tasks-archive-root .searchConsole .searchSelect .react-select__single-value {
  color: #333;
}
#tasks-archive-root .searchConsole .searchSelect .react-select__value-container--has-value .react-select__single-value {
  top: 65%;
}
#tasks-archive-root .searchConsole .searchSelect .react-select__input, #tasks-archive-root .searchConsole .searchSelect .react-select__input input {
  height: 38px !important;
  border: none !important;
}
#tasks-archive-root .searchConsole .searchSelect .react-select__input:focus, #tasks-archive-root .searchConsole .searchSelect .react-select__input input:focus {
  border: none;
  box-shadow: none !important;
}
#tasks-archive-root .searchConsole .search {
  padding-top: 25px;
  line-height: 40px;
}
#tasks-archive-root .searchConsole .search label {
  float: left;
}
#tasks-archive-root .searchConsole .search input {
  margin-top: 10px !important;
  margin-left: 10px !important;
}
#tasks-archive-root .searchConsole .search button {
  float: right;
  width: 200px;
}
#tasks-archive-root .searchConsole input[type=checkbox] {
  height: 10px !important;
  width: 10px !important;
  background: #242424;
  float: left;
  margin-left: 22px;
  cursor: pointer;
  border: none !important;
  box-shadow: none !important;
}
@media screen and (max-width: 768px) {
  #tasks-archive-root .searchConsole input[type=checkbox] {
    margin-left: 0;
  }
}
#tasks-archive-root .searchConsole input[type=checkbox]:checked {
  background: #e13c34;
}
#tasks-archive-root .circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
}
#tasks-archive-root .circle.red {
  background: #e13c34;
}
#tasks-archive-root .circle.green {
  background: #00ff84;
}
