#popup-root {
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 350px;
  background: #e8e8e8;
  z-index: 100000000;
  text-align: center;
}
#popup-root .left, #popup-root .right {
  width: 50%;
}
@media screen and (max-width: 768px) {
  #popup-root .left, #popup-root .right {
    width: 100% !important;
  }
}
#popup-root .container {
  height: auto;
}
@media screen and (max-width: 768px) {
  #popup-root {
    min-height: calc(100vh - 50px) !important;
    position: fixed;
    top: 50px;
    left: 0;
    bottom: auto;
    right: auto;
    margin: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 200px;
  }
}
#popup-root .fa-times {
  color: #e13c34;
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 100;
}
#popup-root h2 {
  font-weight: bold;
  padding: 20px 0 30px 0;
  clear: both;
  font-size: 1.2em;
  margin-top: 70px;
}
#popup-root label {
  float: left;
  font-weight: bold;
  color: #e13c34;
  line-height: 60px;
  margin-right: 15px;
}
@media screen and (max-width: 768px) {
  #popup-root label {
    float: none;
    display: block;
    text-align: left;
  }
}
#popup-root .input {
  display: block;
  width: 100%;
}
#popup-root .input input {
  width: 256px;
  background: #fff;
  color: #252c3a;
  display: block;
  height: 40px;
}
#popup-root .input input::-webkit-input-placeholder {
  color: #252c3a;
}
#popup-root .input input::-moz-placeholder {
  color: #252c3a;
}
#popup-root .input input:-ms-input-placeholder {
  color: #252c3a;
}
#popup-root .input input:-moz-placeholder {
  color: #252c3a;
}
#popup-root .input input[type=checkbox],
#popup-root .input input[type=radio] {
  height: 10px;
  width: 10px;
  background: #242424;
  float: left;
  margin-left: 22px;
  cursor: pointer;
  border: none !important;
  box-shadow: none !important;
}
@media screen and (max-width: 768px) {
  #popup-root .input input[type=checkbox],
  #popup-root .input input[type=radio] {
    margin-left: 0;
  }
}
#popup-root .input input[type=checkbox]:checked,
#popup-root .input input[type=radio]:checked {
  background: #e13c34;
}
#popup-root .input input[type=number] {
  float: right;
  width: 200px;
  margin-top: 12px;
  margin-bottom: 12px;
}
#popup-root span {
  font-size: 0.9em;
  float: left;
  margin-right: 10px;
  line-height: 60px;
}
@media screen and (max-width: 768px) {
  #popup-root span {
    line-height: 1;
    margin-left: 10px;
    margin-right: 30px;
  }
}
#popup-root .primary-btn {
  margin-top: 30px !important;
  width: 200px !important;
}
#popup-root .selectContainer {
  width: 256px;
  position: relative;
  height: 40px;
  margin-top: 10px;
  float: left;
}
#popup-root a {
  cursor: pointer;
  color: #e13c34;
  font-weight: bold;
  font-size: 0.9em;
  margin-top: 20px;
  display: block;
}
#popup-root .ratesC {
  width: 80%;
  float: right;
}
#popup-root .ratesC input {
  float: left !important;
  width: 140px !important;
}
@media screen and (max-width: 768px) {
  #popup-root .ratesC input {
    width: 30% !important;
    margin-right: 10px;
  }
}

#popup-root .expenses .right {
  width: 60%;
}
#popup-root .expenses .left {
  width: 35%;
}
#popup-root .expenses h4 {
  text-align: center;
  color: #252c3a;
  font-weight: bold;
  font-size: 0.9em;
  float: none;
  margin-top: 20px;
}
#popup-root .expenses ul {
  text-align: center;
  max-height: 200px;
  overflow-y: scroll;
}
#popup-root .expenses ul li {
  width: 100%;
  position: relative;
  height: 35px;
}
#popup-root .expenses ul span {
  width: 16%;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 35px;
  cursor: pointer;
}
#popup-root .expenses ul span:hover {
  color: #e13c34;
}
#popup-root .expenses ul span.red {
  color: #e13c34;
  border-right: 1px solid #000;
  cursor: default;
}
#popup-root .expenses .input input {
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  margin: 10px auto;
}
#popup-root .expenses .input input[type=number] {
  float: none;
  width: 256px;
}
#popup-root .expenses .input .selectContainer {
  display: block;
  float: none;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  margin: 10px auto;
}

#popup-root.notifications h3 {
  color: #e13c34;
  font-weight: bold;
  margin: 35px 0 10px 0;
}
@media screen and (max-width: 768px) {
  #popup-root.notifications h3 {
    margin-bottom: 30px;
  }
}
#popup-root.notifications .react-phone-number-input {
  width: 230px;
  margin: 20px 10px;
  height: 40px;
  float: left;
}
@media screen and (max-width: 768px) {
  #popup-root.notifications .react-phone-number-input {
    width: 100%;
    margin: 20px 0;
  }
}
#popup-root.notifications .react-phone-number-input input {
  margin: 0;
  width: auto;
}
#popup-root.notifications .add .input {
  width: 90%;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
}
#popup-root.notifications .add .input input {
  display: inline-block;
  float: left;
  width: 180px;
}
@media screen and (max-width: 768px) {
  #popup-root.notifications .add .input input {
    width: 100%;
  }
}
#popup-root.notifications .add .input a {
  float: right;
  margin-left: 10px;
  line-height: 40px;
}
#popup-root.notifications .set .input {
  border-bottom: 2px solid #e13c34;
  padding: 10px 0;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 80%;
}
#popup-root.notifications .set .input .statuses input {
  padding: 5px;
}
#popup-root.notifications .fa-trash {
  color: #e13c34;
  left: 0;
  position: absolute;
  top: 30px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  #popup-root.notifications .fa-trash {
    left: auto;
    right: 0;
    top: 20px;
  }
}
#popup-root.notifications .set {
  max-height: 150px;
  overflow-y: scroll;
}
@media screen and (max-width: 768px) {
  #popup-root.notifications .set {
    max-height: none;
    overflow: hidden;
  }
  #popup-root.notifications .set input[type=checkbox] {
    top: 0;
    position: relative;
  }
}
@media screen and (max-width: 768px) {
  #popup-root.notifications label {
    line-height: 1;
    text-align: center;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  #popup-root.notifications .set .input, #popup-root.notifications .status .input {
    text-align: left;
  }
}
#popup-root.notifications .set .input input[type=checkbox], #popup-root.notifications .set .input span, #popup-root.notifications .set .input label, #popup-root.notifications .status .input input[type=checkbox], #popup-root.notifications .status .input span, #popup-root.notifications .status .input label {
  float: none;
}
#popup-root.notifications .set .input input[type=checkbox], #popup-root.notifications .status .input input[type=checkbox] {
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  top: 3px;
}
#popup-root.notifications .set .input span, #popup-root.notifications .status .input span {
  margin-right: 0;
  line-height: 30px;
}
@media screen and (max-width: 768px) {
  #popup-root.notifications .set .input span, #popup-root.notifications .status .input span {
    margin-right: 30px;
  }
  #popup-root.notifications .set .input span.name, #popup-root.notifications .status .input span.name {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  #popup-root.notifications .add .input input {
    float: none !important;
  }
}

.notifications_input {
  display: flex !important;
}
@media screen and (max-width: 768px) {
  .notifications_input {
    display: block !important;
  }
  .notifications_input .PhoneInput {
    width: 100%;
    margin: 15px 0 !important;
  }
  .notifications_input input {
    width: 100%;
  }
}
