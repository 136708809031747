.__react_modal_image__modal_container {
  width: calc(100% - 250px) !important;
  left: auto !important;
  right: 0 !important;
}
@media screen and (max-width: 768px) {
  .__react_modal_image__modal_container {
    width: 100% !important;
    height: calc(100% - 50px) !important;
    top: 50px !important;
  }
}

#task-data-root {
  width: 100%;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: auto;
  background: #fff;
  top: 0;
  padding: 30px 0;
  z-index: 2;
}
#task-data-root.clientView {
  min-height: 100vh;
}
#task-data-root .container {
  height: auto;
}
#task-data-root .addLocation {
  color: #e13c34;
  cursor: pointer;
  font-size: 12px;
}
#task-data-root .addressPopup {
  background: #242424;
  width: 400px;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  top: 100px;
  height: 110px;
  z-index: 1000;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
}
@media screen and (max-width: 768px) {
  #task-data-root .addressPopup {
    width: 80%;
    height: 160px;
    text-align: center;
  }
}
#task-data-root .addressPopup i {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.5em;
  margin: 0;
}
@media screen and (max-width: 768px) {
  #task-data-root .addressPopup i {
    position: relative;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 100%;
    margin: 10px auto;
    top: 0;
  }
}
#task-data-root .addressPopup input, #task-data-root .addressPopup select {
  width: 80%;
  position: relative;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  margin: 10px auto;
}
#task-data-root .fa-times {
  color: #e13c34;
  float: right;
  margin-right: 30px;
  font-size: 3em;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
}
#task-data-root .fa-times:hover {
  color: #e13c34;
}
#task-data-root .react-timekeeper {
  position: absolute !important;
  left: 0;
  top: 55px;
  width: 300px !important;
  position: relative;
  z-index: 10000;
}
#task-data-root #map {
  width: 60%;
  height: 300px;
  float: right;
}
@media screen and (max-width: 768px) {
  #task-data-root #map {
    clear: both;
    width: 100%;
    margin-bottom: 40px;
  }
}
#task-data-root .buttons {
  clear: both;
  float: right;
  margin-top: 50px;
  margin-bottom: 30px;
}
#task-data-root .buttons button {
  width: 250px;
  height: 50px;
  margin-left: 15px;
}
#task-data-root h3 {
  color: #e13c34;
  font-weight: bold;
  margin-bottom: 20px;
}
#task-data-root .box, #task-data-root .summery {
  width: 25%;
  float: left;
  margin-bottom: 30px;
}
#task-data-root .box.imageBox, #task-data-root .summery.imageBox {
  width: 100%;
}
#task-data-root .box.imageBox li, #task-data-root .summery.imageBox li {
  display: inline-block;
  margin-right: 5px;
  width: 100px !important;
  vertical-align: top;
}
#task-data-root .box.imageBox li strong, #task-data-root .summery.imageBox li strong {
  padding-bottom: 5px;
  display: block;
}
@media screen and (max-width: 768px) {
  #task-data-root .box, #task-data-root .summery {
    width: 100%;
  }
}
#task-data-root .box .click, #task-data-root .summery .click {
  cursor: pointer;
}
#task-data-root .box .red, #task-data-root .summery .red {
  color: #e13c34;
}
#task-data-root .box strong, #task-data-root .summery strong {
  font-weight: bold;
}
#task-data-root .box li, #task-data-root .summery li {
  margin: 10px 0 10px 2px;
  position: relative;
}
#task-data-root .box li .react-timekeeper, #task-data-root .summery li .react-timekeeper {
  top: 0;
  left: calc(100% + 15px);
}
#task-data-root .box li .fa-times, #task-data-root .summery li .fa-times {
  position: relative;
  color: #000;
  font-size: 0.9em;
  float: none;
  margin-right: 5px;
  margin-bottom: 0;
}
#task-data-root .box li.location, #task-data-root .summery li.location {
  width: 220px;
}
#task-data-root .box li.location > span, #task-data-root .summery li.location > span {
  float: right;
  cursor: pointer;
  text-decoration: underline;
}
#task-data-root .box li.signature, #task-data-root .box li.expense, #task-data-root .box li.image, #task-data-root .summery li.signature, #task-data-root .summery li.expense, #task-data-root .summery li.image {
  width: 220px;
}
#task-data-root .box li.signature .sigData, #task-data-root .box li.signature .expenseData, #task-data-root .box li.signature .imageData, #task-data-root .box li.expense .sigData, #task-data-root .box li.expense .expenseData, #task-data-root .box li.expense .imageData, #task-data-root .box li.image .sigData, #task-data-root .box li.image .expenseData, #task-data-root .box li.image .imageData, #task-data-root .summery li.signature .sigData, #task-data-root .summery li.signature .expenseData, #task-data-root .summery li.signature .imageData, #task-data-root .summery li.expense .sigData, #task-data-root .summery li.expense .expenseData, #task-data-root .summery li.expense .imageData, #task-data-root .summery li.image .sigData, #task-data-root .summery li.image .expenseData, #task-data-root .summery li.image .imageData {
  display: block;
  width: 100%;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
#task-data-root .box li.signature .expenseData, #task-data-root .box li.signature .imageData, #task-data-root .box li.expense .expenseData, #task-data-root .box li.expense .imageData, #task-data-root .box li.image .expenseData, #task-data-root .box li.image .imageData, #task-data-root .summery li.signature .expenseData, #task-data-root .summery li.signature .imageData, #task-data-root .summery li.expense .expenseData, #task-data-root .summery li.expense .imageData, #task-data-root .summery li.image .expenseData, #task-data-root .summery li.image .imageData {
  background-size: cover;
  margin-top: 10px;
}
#task-data-root .box li .number, #task-data-root .summery li .number {
  background: #e13c34;
  border-radius: 50%;
  color: #fff;
  height: 15px;
  width: 15px;
  margin: 0 5px;
  display: inline-block;
  text-align: center;
  line-height: 15px;
  font-size: 0.7em;
}
#task-data-root .box li h6, #task-data-root .summery li h6 {
  padding: 10px 0 10px 20px;
  cursor: pointer;
  font-size: 0.8em;
  line-height: 1.4;
}

#client-data-root .summery {
  width: 100%;
  border-bottom: 1px solid #e13c34;
  min-height: 1px;
  padding: 30px 0 60px 0;
  float: none;
}
@media screen and (max-width: 768px) {
  #client-data-root .summery {
    padding: 20px 0;
  }
}
#client-data-root .summery .react-datepicker-wrapper {
  float: none;
  width: auto;
}
#client-data-root .summery input {
  text-align: center;
  height: auto;
  width: 80px;
  border: none;
  padding: 0;
  margin: 0;
  text-indent: 0;
  display: inline;
  float: none;
}
#client-data-root .summery .info {
  display: inline-block;
  position: relative;
  margin-right: 10px;
}
@media screen and (max-width: 768px) {
  #client-data-root .summery .info {
    line-height: 1.8;
  }
}
#client-data-root .data {
  margin-top: 60px;
  display: block;
}

.clientUrl {
  background: #00ff84;
  text-align: center;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}
