#create-task-root .multiSelect {
  height: auto !important;
  text-align: left;
}
#create-task-root .multiSelect .react-select__control,
#create-task-root .multiSelect .react-select__value-container,
#create-task-root .multiSelect .react-select__input,
#create-task-root .multiSelect .react-select__input input {
  height: auto !important;
}
#create-task-root .multiSelect.split_r {
  width: 49% !important;
  float: right;
}
#create-task-root .multiSelect.split_l {
  width: 49% !important;
  float: left;
}
#create-task-root .multiSelect .react-select__input,
#create-task-root .multiSelect .react-select__input input {
  min-height: 32px;
  border: none !important;
}
#create-task-root .multiSelect .react-select__input:focus,
#create-task-root .multiSelect .react-select__input input:focus {
  border: none;
  box-shadow: none !important;
}

.rc-time-picker {
  width: 100%;
}

#popup-root .multiSelect .react-select__input,
#popup-root .multiSelect .react-select__input input {
  min-height: 1px;
  margin: 0 !important;
}

.billablePopup {
  z-index: 1000;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.billablePopup .inner {
  background: #f7f7f7;
  height: 200px;
  width: 600px;
  line-height: 200px;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .billablePopup .inner {
    width: 100%;
  }
}
.billablePopup .inner i {
  position: absolute;
  top: -40px;
  right: -80px;
}
@media screen and (max-width: 768px) {
  .billablePopup .inner i {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    top: -120px;
  }
}
@media screen and (max-width: 768px) {
  .billablePopup .inner .buttons {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    top: 0;
  }
}
.billablePopup .inner button {
  margin: 0 20px;
}
.billablePopup .inner button:hover {
  background: #00ff84;
  color: #000;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  position: inherit !important;
  display: inherit !important;
  height: 100%;
  width: 100%;
}

.react-datepicker-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.timerPicker {
  position: relative;
  z-index: 10000;
}

.react-timekeeper {
  position: absolute !important;
  left: 0;
  top: 55px;
  width: 100% !important;
}
@media screen and (max-width: 768px) {
  .react-timekeeper {
    position: fixed !important;
    left: 0;
    top: 50px;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 1000000000;
  }
  .react-timekeeper div {
    left: 0 !important;
  }
}

#create-task-root input:not(:placeholder-shown) {
  border: 1px solid #00ff84 !important;
}

#edit-account-root h2 {
  color: #fff;
  text-align: left;
  font-size: 1.2em;
  margin-top: 30px;
  margin-left: 10px;
}
#edit-account-root .subProvider {
  margin-top: 0;
}
#edit-account-root .addSub,
#edit-account-root .removeSub {
  color: #e13c34;
  background: none;
  float: right;
  font-size: 1em;
  margin-right: 10px;
  margin-top: 20px;
  cursor: pointer;
}
#edit-account-root .removeSub {
  font-size: 2em;
  position: absolute;
  right: -25px;
  line-height: 40px;
}

#create-task-root,
#edit-account-root {
  background: #fff;
  width: 95%;
  max-width: 1100px;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 2;
  height: auto;
  padding: 30px 0;
  text-align: center;
}
#create-task-root .fa-times,
#edit-account-root .fa-times {
  color: #000;
  float: right;
  margin-right: 30px;
  font-size: 3em;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  #create-task-root .fa-times,
  #edit-account-root .fa-times {
    float: none;
    position: relative;
    right: 0;
    left: 0;
    margin: 0 auto;
    font-size: 2em;
    margin-bottom: 20px;
  }
}
#create-task-root .fa-times:hover,
#edit-account-root .fa-times:hover {
  color: #e13c34;
}
#create-task-root .taskData,
#edit-account-root .taskData {
  background: #fff000;
  color: #242424;
  height: 50px;
  width: 200px;
  margin-left: 10px;
  float: left;
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 40px;
  transition: all 0.3s ease;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  #create-task-root .taskData,
  #edit-account-root .taskData {
    width: 90% !important;
    display: block;
    float: none !important;
    margin: 20px auto !important;
  }
}
#create-task-root .taskData.grey,
#edit-account-root .taskData.grey {
  background: #e8e8e8;
}
#create-task-root .taskData.green,
#edit-account-root .taskData.green {
  background: #e13c34;
  color: #fff;
}
#create-task-root .taskData:hover,
#edit-account-root .taskData:hover {
  background: #fff;
  color: #242424;
}
#create-task-root .update,
#edit-account-root .update {
  background: #242424;
  color: #fff;
  font-weight: bold;
  font-size: 1em;
  width: calc(100% - 20px);
  margin: 40px 10px;
  height: 65px;
  cursor: pointer;
  transition: all 0.3s ease;
}
#create-task-root .update.updated,
#edit-account-root .update.updated {
  background: #242424;
  color: #fff;
  cursor: pointer !important;
}
#create-task-root .update.updated:hover,
#edit-account-root .update.updated:hover {
  background: #f4f4f4;
  color: #000;
}
#create-task-root .update:hover,
#edit-account-root .update:hover {
  background: #242424;
  color: #e13c34;
}
#create-task-root .export,
#edit-account-root .export {
  background: #00ff84;
  color: #242424;
  font-weight: bold;
  font-size: 1em;
  width: 276px;
  margin: 10px 10px;
  height: 45px;
  cursor: pointer;
  transition: all 0.3s ease;
}
@media screen and (max-width: 768px) {
  #create-task-root .export,
  #edit-account-root .export {
    width: 90% !important;
    display: block;
    float: none !important;
    margin: 20px auto !important;
  }
}
#create-task-root .export:hover,
#edit-account-root .export:hover {
  background: #fff;
  color: #242424;
}
#create-task-root form,
#edit-account-root form {
  width: 100%;
  max-width: 920px;
  clear: both;
  position: relative;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
}
#create-task-root form .primary-btn,
#create-task-root form .second-btn,
#edit-account-root form .primary-btn,
#edit-account-root form .second-btn {
  height: 45px;
  margin: 10px 10px;
  width: 30%;
}
@media screen and (max-width: 768px) {
  #create-task-root form .primary-btn,
  #create-task-root form .second-btn,
  #edit-account-root form .primary-btn,
  #edit-account-root form .second-btn {
    width: 90% !important;
    display: block;
    float: none !important;
    margin: 20px auto !important;
  }
}
#create-task-root form .addressPicker,
#create-task-root form .addressNamePicker,
#edit-account-root form .addressPicker,
#edit-account-root form .addressNamePicker {
  position: relative;
  width: auto;
  display: inline;
  z-index: 3;
  width: calc(100% - 20px) !important;
}
@media screen and (max-width: 768px) {
  #create-task-root form .addressPicker,
  #create-task-root form .addressNamePicker,
  #edit-account-root form .addressPicker,
  #edit-account-root form .addressNamePicker {
    width: 100% !important;
    display: inline-block;
  }
}
#create-task-root form .addressPicker .buttons,
#create-task-root form .addressNamePicker .buttons,
#edit-account-root form .addressPicker .buttons,
#edit-account-root form .addressNamePicker .buttons {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 100%;
  width: auto;
  right: 0;
  line-height: 50px;
}
@media screen and (max-width: 768px) {
  #create-task-root form .addressPicker .buttons,
  #create-task-root form .addressNamePicker .buttons,
  #edit-account-root form .addressPicker .buttons,
  #edit-account-root form .addressNamePicker .buttons {
    display: none;
  }
}
#create-task-root form .addressPicker button,
#create-task-root form .addressNamePicker button,
#edit-account-root form .addressPicker button,
#edit-account-root form .addressNamePicker button {
  color: #e13c34;
  background: #242424;
  margin-right: 2px;
  font-size: 1.8em;
  cursor: pointer;
}
#create-task-root form .timerPicker,
#create-task-root form .addressPicker,
#create-task-root form .addressNamePicker,
#edit-account-root form .timerPicker,
#edit-account-root form .addressPicker,
#edit-account-root form .addressNamePicker {
  border: none !important;
}
#create-task-root form input,
#create-task-root form textarea,
#create-task-root form .chat,
#create-task-root form .addressPicker,
#create-task-root form .timerPicker,
#create-task-root form .addressNamePicker,
#edit-account-root form input,
#edit-account-root form textarea,
#edit-account-root form .chat,
#edit-account-root form .addressPicker,
#edit-account-root form .timerPicker,
#edit-account-root form .addressNamePicker {
  background: #f4f4f4;
  color: #000;
  height: 50px;
  display: inline-block;
  text-align: left;
  margin: 20px 10px;
  font-size: 0.9em;
  width: 100%;
  border: 1px solid #000;
}
@media screen and (max-width: 768px) {
  #create-task-root form input,
  #create-task-root form textarea,
  #create-task-root form .chat,
  #create-task-root form .addressPicker,
  #create-task-root form .timerPicker,
  #create-task-root form .addressNamePicker,
  #edit-account-root form input,
  #edit-account-root form textarea,
  #edit-account-root form .chat,
  #edit-account-root form .addressPicker,
  #edit-account-root form .timerPicker,
  #edit-account-root form .addressNamePicker {
    margin: 0;
  }
}
#create-task-root form input::-webkit-input-placeholder,
#create-task-root form textarea::-webkit-input-placeholder,
#create-task-root form .chat::-webkit-input-placeholder,
#create-task-root form .addressPicker::-webkit-input-placeholder,
#create-task-root form .timerPicker::-webkit-input-placeholder,
#create-task-root form .addressNamePicker::-webkit-input-placeholder,
#edit-account-root form input::-webkit-input-placeholder,
#edit-account-root form textarea::-webkit-input-placeholder,
#edit-account-root form .chat::-webkit-input-placeholder,
#edit-account-root form .addressPicker::-webkit-input-placeholder,
#edit-account-root form .timerPicker::-webkit-input-placeholder,
#edit-account-root form .addressNamePicker::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000;
}
#create-task-root form input::-moz-placeholder,
#create-task-root form textarea::-moz-placeholder,
#create-task-root form .chat::-moz-placeholder,
#create-task-root form .addressPicker::-moz-placeholder,
#create-task-root form .timerPicker::-moz-placeholder,
#create-task-root form .addressNamePicker::-moz-placeholder,
#edit-account-root form input::-moz-placeholder,
#edit-account-root form textarea::-moz-placeholder,
#edit-account-root form .chat::-moz-placeholder,
#edit-account-root form .addressPicker::-moz-placeholder,
#edit-account-root form .timerPicker::-moz-placeholder,
#edit-account-root form .addressNamePicker::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
}
#create-task-root form input:-ms-input-placeholder,
#create-task-root form textarea:-ms-input-placeholder,
#create-task-root form .chat:-ms-input-placeholder,
#create-task-root form .addressPicker:-ms-input-placeholder,
#create-task-root form .timerPicker:-ms-input-placeholder,
#create-task-root form .addressNamePicker:-ms-input-placeholder,
#edit-account-root form input:-ms-input-placeholder,
#edit-account-root form textarea:-ms-input-placeholder,
#edit-account-root form .chat:-ms-input-placeholder,
#edit-account-root form .addressPicker:-ms-input-placeholder,
#edit-account-root form .timerPicker:-ms-input-placeholder,
#edit-account-root form .addressNamePicker:-ms-input-placeholder {
  /* IE 10+ */
  color: #000;
}
#create-task-root form input:-moz-placeholder,
#create-task-root form textarea:-moz-placeholder,
#create-task-root form .chat:-moz-placeholder,
#create-task-root form .addressPicker:-moz-placeholder,
#create-task-root form .timerPicker:-moz-placeholder,
#create-task-root form .addressNamePicker:-moz-placeholder,
#edit-account-root form input:-moz-placeholder,
#edit-account-root form textarea:-moz-placeholder,
#edit-account-root form .chat:-moz-placeholder,
#edit-account-root form .addressPicker:-moz-placeholder,
#edit-account-root form .timerPicker:-moz-placeholder,
#edit-account-root form .addressNamePicker:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
}

.third {
  width: 31% !important;
  position: relative;
  display: inline-block;
  height: auto;
  margin: 20px 10px;
  float: left;
}
@media screen and (max-width: 768px) {
  .third {
    width: 90% !important;
    display: block;
    float: none;
    margin: 20px auto !important;
  }
  .third.timerPicker {
    float: left;
    margin-left: 5% !important;
    width: 42% !important;
  }
  .third.timerPicker.right {
    margin-right: 5% !important;
    float: right;
  }
}
.third.selectBox {
  margin: 9px 10px;
  width: 30% !important;
}
@media screen and (max-width: 768px) {
  .third.selectBox {
    width: 90% !important;
    display: block;
    float: none !important;
    margin: 20px auto !important;
  }
}
.third input,
.third textarea {
  width: 100% !important;
  margin: 0 !important;
  height: 50px !important;
}
.third textarea {
  height: 120px !important;
}

.full {
  width: calc(100% - 20px) !important;
}
@media screen and (max-width: 768px) {
  .full {
    width: 90% !important;
    display: block;
    float: none;
    margin: 20px auto !important;
  }
}

.s {
  width: 19% !important;
}
@media screen and (max-width: 768px) {
  .s {
    width: 90% !important;
    display: block;
    float: none;
    margin: 20px auto !important;
  }
}

.m {
  width: 26% !important;
}
@media screen and (max-width: 768px) {
  .m {
    width: 90% !important;
    display: block;
    float: none;
    margin: 20px auto !important;
  }
}

.xm {
  width: 30% !important;
}
@media screen and (max-width: 768px) {
  .xm {
    width: 90% !important;
    display: block;
    float: none !important;
    margin: 20px auto !important;
  }
}

.xl {
  width: 65% !important;
}
@media screen and (max-width: 768px) {
  .xl {
    width: 90% !important;
    display: block;
    float: none !important;
    margin: 20px auto !important;
  }
}

@media screen and (max-width: 768px) {
  .addressBlock {
    width: 90% !important;
    float: none !important;
    position: relative;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
  }
  .addressBlock .buttonsMobile {
    clear: both;
    text-align: left;
  }
  .addressBlock .buttonsMobile button {
    color: #e13c34;
    background: #242424;
    margin-right: 2px;
    font-size: 1.8em;
    cursor: pointer;
  }
}
